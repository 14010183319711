import React, { useEffect, useRef, useState } from 'react';
import LiveChat from 'react-livechat';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'gatsby-plugin-intl';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BillingAddressForm from '../components/billing-address-form';
import BillingPaymentForm from '../components/billing-payment-form/billing-payment-form';
import CheckoutTotal from '../components/checkout-total';
import {
  getInstanceName,
  getLiveChatParams,
  installmentCountries,
  isClient,
  isOnlineLineInstance,
  isUserLogged,
  SIGNING_AUDIT_TRAIL_TYPE,
} from '../helpers/utils';
import {
  addToCart,
  makeOrderWithDLocalInstallment,
  makeOrderWithSavedCart,
  setAuditTrailStatus,
  setAuditTrialData,
  setModal,
  updateCardInfo,
} from '../store/actions';
import {
  selectAndCheckIfAgreementSignedForCurrentPlanInTheCart,
  selectBillingAddresses,
  selectCart,
  selectCoinPaymentDiscount,
  selectConditionToShowPurchaseOrSignButton,
  selectIbpProduct,
  selectIfIbpInTheCart,
  selectIfUserNeedToSignAgreement,
  selectPaymentCardForCvvConfirm,
  selectPaymentsWithDefault,
  selectPreAuthorizeModalCondition,
  selectShowVatAmount,
  showAutoRenewalMessage,
} from '../store/selectors';
import {
  selectAuditTrial,
  selectCalculatedPrice,
  selectElectronicSignments,
  selectEntityUserDetails,
  selectEntityUserSubscriptions,
} from '../store/selectors/entities';
import { selectChosenPaymentMethod, selectModal } from '../store/selectors/global';
import setNotification from '../helpers/notifications';
import { createNavigateTo, externalLinks, pageLinks } from '../helpers/navigation';
import styles from '../styles/pages/checkout.module.scss';
import CouponInput from '../components/coupon-input';
import BannerRefferal from '../components/banner-refferal';
import BannerBitcoinDiscountHeader from '../components/banner-btc-discount';
import AutoRenewInfoModal from '../components/autoRenewInfoModal';
import CheckoutLinks from '../components/checkout-static-links/static-links';
import UpdateCvvModal from '../components/update-cvv-modal';
import SignPdfModal from '../components/sign-pdf-modal';
import showPdfAgreement from '../components/checkout-total/showPdfAgreement';
import PreAuthorisationModal from '../components/preauthorisation-modal';
import Button from '../components/button';

const query = graphql`
  query {
    ibpFluid: file(relativePath: { eq: "products/independant-brand-promoter-170x170.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 170) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    prismic {
      allCheckoutpages {
        edges {
          node {
            onendofsubscription
          }
        }
      }
    }
  }
`;

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  billingAddresses: selectBillingAddresses,
  selectedPaymentMethod: selectChosenPaymentMethod,
  modal: selectModal,
  userDetails: selectEntityUserDetails,
  showVat: selectShowVatAmount,
  savedMethods: selectPaymentsWithDefault,
  showAutoRenewal: showAutoRenewalMessage,
  preAuthorizeCondition: selectPreAuthorizeModalCondition,
  electronicSignments: selectElectronicSignments,
  isAgreementSignedForCurrentCart: selectAndCheckIfAgreementSignedForCurrentPlanInTheCart,
  showPurchaseOrSign: selectConditionToShowPurchaseOrSignButton,
  cvvCardForConfirmation: selectPaymentCardForCvvConfirm,
  coinPaymentDiscount: selectCoinPaymentDiscount,
  calculatedPrice: selectCalculatedPrice,
  auditTrial: selectAuditTrial,
  ibpProduct: selectIbpProduct,
  isIbpInTheCart: selectIfIbpInTheCart,
  shouldSignAgreement: selectIfUserNeedToSignAgreement,
  subscriptions: selectEntityUserSubscriptions,
});

const Checkout = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const imgData = useStaticQuery(query);

  const [showAutoPayModal, toggleAutoPayModal] = React.useState(null);
  const [modalPlanName, setModalPlanName] = React.useState('');
  const [showSignPdf, toggleSignPdf] = useState(false);
  const [showPreAuthorizeModal, togglePreAuthorizeModal] = React.useState(false);
  const liveChatRef = useRef(null);
  const ibpFluid = imgData?.ibpFluid.childImageSharp.fluid;

  const {
    cartData,
    billingAddresses,
    selectedPaymentMethod,
    modal,
    userDetails,
    showVat,
    savedMethods,
    showAutoRenewal,
    electronicSignments,
    cvvCardForConfirmation,
    coinPaymentDiscount,
    calculatedPrice,
    auditTrial,
    shouldSignAgreement,
    subscriptions,
    ibpProduct,
  } = useSelector(stateSelector);

  const { data } = props;
  const showCvvModal = modal === 'CVV_MODAL';

  const isEwalletOrBtcPaymentMethod = false; //

  const onEndOfSubscription =
    data && RichText.asText(data.prismic.allCheckoutpages.edges[0].node.onendofsubscription);
  const recurringPrice = cartData.products.reduce((acc, product) => {
    let accumulator = acc;
    accumulator += product.recurringPrice || 0;
    return accumulator;
  }, 0);

  const onEndOfSubscriptionWithTheValue =
    onEndOfSubscription &&
    onEndOfSubscription.replace('*CHARGED_MONEY_AMOUNT*', `$${recurringPrice}`);

  const showInitialDiscount = cartData && cartData.showInitialDiscount;

  const [showBtcDiscount, setShowBtcDiscount] = useState(
    showInitialDiscount && isEwalletOrBtcPaymentMethod
  );

  const primaryAddress = billingAddresses && billingAddresses.find((item) => !!item.primary);
  const isPrimaryAddress = !!primaryAddress;
  const isEntry = cartData?.products?.some((product) => !product.isRenew && !product.isUpgrade);
  const liveChatParams = getLiveChatParams(userDetails, subscriptions);

  const changeAuditTrailStatus = (step, externalId) => {
    dispatch(setAuditTrailStatus({ step, externalId }));
  };

  useEffect(() => {
    if (isClient && !isUserLogged()) {
      createNavigateTo(pageLinks.signUp2)();
    }
  });

  useEffect(() => {
    dispatch(setAuditTrialData({ id: '', step: 0 }));

    if (isClient) {
      try {
        localStorage.removeItem('audit-trial-id');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('CHECKOUT setAuditTrialData', err);
      }
    }
  }, []);

  useEffect(() => {
    const isRenew = cartData?.products?.some((product) => product.isRenew);
    const isIBIBought = subscriptions?.some((item) => item.name === 'Publisher');

    if (isIBIBought && !isRenew) {
      if (ibpProduct) {
        dispatch(addToCart('product', ibpProduct));
      }
    }
  }, [ibpProduct, subscriptions, cartData, selectedPaymentMethod?.method]);

  useEffect(() => {
    if (liveChatRef?.current?.disable_sounds) {
      liveChatRef?.current?.disable_sounds();
    }
  }, [liveChatRef?.current]);

  const submitPayment = () => dispatch(makeOrderWithSavedCart());

  const checkAndSubmit = () => {
    if (cartData && !cartData.productCount) {
      setNotification('error', {
        message: 'Shopping cart is empty',
        title: 'Error',
      });
      return;
    }

    if (!isPrimaryAddress) {
      setNotification('error', {
        message: 'Please add/select your primary address',
        title: 'Error',
      });
      return;
    }

    if (cvvCardForConfirmation) {
      dispatch(setModal('CVV_MODAL'));
      return;
    }

    submitPayment();
  };

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.checkout' });

  const toggleModal = (name = '') => {
    dispatch(setModal(name));
  };

  const hideBtcDiscount = () => setShowBtcDiscount(false);

  let installmentData = null;

  if (
    isOnlineLineInstance &&
    primaryAddress?.country &&
    savedMethods &&
    installmentCountries.includes(primaryAddress.country.toUpperCase())
  ) {
    const cardSelected =
      selectedPaymentMethod?.method === 'payment-card' && selectedPaymentMethod.isDefault;

    const defaultCardPresent = savedMethods?.cards.find((item) => item.isDefault);

    installmentData = cardSelected || defaultCardPresent;
  }

  const submitPaymentWithInstallments = () => {
    const { id, method } = installmentData;

    // eslint-disable-next-line no-shadow
    const data = {
      gatewayAccountId: 'dlocal-installment',
      paymentInstrumentId: id,
      method,
    };

    dispatch(makeOrderWithDLocalInstallment(data));
  };

  const showPdf = (action) => {
    if (!isClient) {
      return;
    }
    try {
      // eslint-disable-next-line consistent-return
      return showPdfAgreement(action, userDetails);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while showing PDF Document');
    }
  };

  // eslint-disable-next-line no-shadow
  const onSaveCvv = (data) => {
    dispatch(updateCardInfo(data));
  };

  const selectAnotherPayment = () => {
    dispatch(setModal('change'));
  };

  const hideCvvModal = () => {
    dispatch(setModal(null));
  };

  const redirectToAlternativePm = () => {
    window.location.href = externalLinks.alternativePaymentMethod;
  };

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      {showBtcDiscount && <BannerBitcoinDiscountHeader close={hideBtcDiscount} />}
      <Layout pageWrapperClassName="page_wraper">
        <div className="refferalWrapper">
          <BannerRefferal />
        </div>
        <h2 className="common_title color_primary_dark">{title}</h2>
        <div>
          <div>
            <div className={styles.wrapper}>
              <div className={styles.right_side}>
                <BillingAddressForm toggleModal={toggleModal} modal={modal} />
                {isPrimaryAddress && (
                  <BillingPaymentForm
                    toggleModal={toggleModal}
                    modal={modal}
                    showInitialDiscount={showInitialDiscount}
                  />
                )}

                <CheckoutLinks
                  countryCode={userDetails && userDetails.country}
                  onEndOfSubscriptionWithTheValue={onEndOfSubscriptionWithTheValue}
                />
              </div>

              <div className={styles.left_side}>
                {process.env.GATSBY_INSTANCE_NAME === 'befreedom' && (
                  <Button
                    className={styles.alternativeButton}
                    variant="outlined"
                    fullWidth
                    size="large"
                    withArrow
                    component="button"
                    onClick={redirectToAlternativePm}
                  >
                    Alternative Checkout Shop
                  </Button>
                )}

                <div className={styles.coupon}>
                  <CouponInput page="checkout" />
                </div>

                {showAutoPayModal && (
                  <AutoRenewInfoModal
                    plans={modalPlanName}
                    fullName={userDetails.fullName}
                    hideModal={() => toggleAutoPayModal(false)}
                    onConfirm={submitPayment}
                  />
                )}

                {showCvvModal && cvvCardForConfirmation && (
                  <UpdateCvvModal
                    card={cvvCardForConfirmation}
                    fullName={userDetails?.fullName}
                    hideModal={hideCvvModal}
                    onConfirm={onSaveCvv}
                    onSkipClick={selectAnotherPayment}
                  />
                )}

                {showPreAuthorizeModal && (
                  <PreAuthorisationModal
                    hideModal={() => togglePreAuthorizeModal(false)}
                    countryCode={userDetails && userDetails.country}
                    onSkipClick={submitPayment}
                  />
                )}

                <CheckoutTotal
                  className={styles.purchaseTotal}
                  onSubmit={checkAndSubmit}
                  onInstallmentsPayment={submitPaymentWithInstallments}
                  isEntry={isEntry}
                  isPrimaryAddress={isPrimaryAddress}
                  isPaymentSet={selectedPaymentMethod}
                  cartData={cartData}
                  showVat={showVat}
                  showBtcDiscount={isEwalletOrBtcPaymentMethod}
                  showInstallmentsButton={!!installmentData}
                  showAgreement={async () => {
                    await changeAuditTrailStatus(SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step);
                    toggleSignPdf(true);
                  }}
                  userDetails={userDetails}
                  showAutoRenewalMessage={showAutoRenewal}
                  electronicSignments={electronicSignments}
                  billingAddresses={billingAddresses}
                  savedMethods={savedMethods}
                  shouldSignAgreement={shouldSignAgreement}
                  coinPaymentDiscount={coinPaymentDiscount}
                  ibpFluid={ibpFluid}
                  calculatedPrice={calculatedPrice}
                />
              </div>
            </div>
          </div>
        </div>

        {showSignPdf &&
          (calculatedPrice?.totalAmount === 0 ||
            (calculatedPrice?.totalAmount > 0 && selectedPaymentMethod?.method)) && (
            <SignPdfModal
              isModal
              show={showSignPdf}
              toggle={toggleSignPdf}
              onSubmit={submitPayment}
              userDetails={userDetails}
              cartData={cartData}
              electronicSignments={electronicSignments}
              showPdf={() => showPdf('getBlob')}
              billingAddresses={billingAddresses}
              setAuditTrialStatus={changeAuditTrailStatus}
              externalId={auditTrial.id}
              calculatedPrice={calculatedPrice}
            />
          )}

        {isUserLogged() && (
          <LiveChat
            visitor={{
              name: `${userDetails?.firstName} ${userDetails?.lastName}`,
              email: userDetails?.email,
            }}
            license={process.env.GATSBY_LIVECHAT_LICENSE}
            onChatLoaded={(ref) => {
              liveChatRef.current = ref;
            }}
            params={liveChatParams}
          />
        )}
      </Layout>
    </>
  );
};

Checkout.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allCheckoutpages: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            onendofsubscription: PropTypes.string.isRequired,
          }),
        })
      ).isRequired,
    }),
  }).isRequired,
};

export default Checkout;
