import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import Modal from '../modal';
import Input from '../input';
import CountrySelect from '../country-select';
import BillingAddressBlock from '../billing-address-block';
import InputRadio from '../input-radio';
import TitleButton from '../title-button';
import Button from '../button';
import styles from './billing-address-modal-form.module.scss';
import InputPhone from '../input-phone';
import StateSelect from '../state-select';

const BillingAddressModalForm = (props) => {
  const {
    fk,
    modal,
    showModal,
    onUpdateAddressClick,
    onAddAddressClick,
    onDeleteAddress,
    defaultAddress,
    otherAddresses,
    selectedAddress,
    setAddressAsDefault,
    setSelected,
    setAction,
    modalError,
  } = props;

  const intl = useIntl();

  const removeText = intl.formatMessage({ id: 'button.remove' });
  const setDefaultText = intl.formatMessage({ id: 'button.setDefault' });
  const cancel = intl.formatMessage({ id: 'button.cancel' });

  const updateAddress = intl.formatMessage({ id: 'button.updateAddress' });
  const saveText = intl.formatMessage({ id: 'button.save' });
  const phone = 'Phone (Enter cardholders mobile phone number)';
  const phoneNumber = intl.formatMessage({ id: 'phoneNumber' });
  const defaultAddressText = intl.formatMessage({ id: 'purchase_checkout.default_address' });

  const otherAddressText = intl.formatMessage({ id: 'purchase_checkout.other_address' });
  const billingAddressText = intl.formatMessage({ id: 'purchase_checkout.billing_adress' });

  const isDisabled = !fk.isValid;

  const showErrorMessage = (id) => (fk.touched[id] ? fk.errors[id] : '');

  const isState = fk.values?.country?.toLowerCase() === 'us';

  const renderForm = () => (
    <form>
      <div className={styles.row}>
        <Input
          label={intl.formatMessage({ id: 'purchase_checkout_form.first_name' })}
          id="firstName"
          placeholder="Enter First Name"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.firstName}
          errorMessage={showErrorMessage('firstName')}
          required
          fullwidth
        />

        <Input
          label={intl.formatMessage({ id: 'purchase_checkout_form.last_name' })}
          id="lastName"
          placeholder="Enter Last Name"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.lastName}
          errorMessage={showErrorMessage('lastName')}
          required
          fullwidth
        />

        <CountrySelect
          id="country"
          value={fk.values.country}
          label={intl.formatMessage({ id: 'purchase_checkout_form.country' })}
          placeholder="Select your country"
          onChange={(e) => {
            fk.setFieldValue('country', e.value);
            fk.setFieldValue('countryCode', '');
          }}
          required
          isSearchable
          errorMessage={showErrorMessage('country')}
        />

        {isState && (
          <StateSelect
            id="state"
            value={fk.values.state}
            label={intl.formatMessage({ id: 'purchase_checkout_form.state' })}
            placeholder="Select your state"
            onChange={(e) => {
              fk.setFieldValue('state', e.value);
            }}
            required
            errorMessage={showErrorMessage('state')}
          />
        )}

        <Input
          label={intl.formatMessage({ id: 'purchase_checkout_form.city' })}
          id="city"
          placeholder="Enter your city"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.city}
          errorMessage={showErrorMessage('city')}
          required
          fullwidth
        />
        <InputPhone
          id="phone"
          label={phone}
          placeholder={phoneNumber}
          onChange={fk.handleChange}
          setFieldValue={fk.setFieldValue}
          onBlur={fk.handleBlur}
          value={fk.values.phone}
          countryCode={fk.values.countryCode}
          country={fk.values.country}
          fullwidth
          type="tel"
          required
          errorMessage={showErrorMessage('phone')}
        />

        <Input
          label={intl.formatMessage({ id: 'purchase_checkout_form.address' })}
          id="address"
          placeholder="Enter your address"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.address}
          errorMessage={showErrorMessage('address')}
          fullwidth
          required
        />

        <Input
          label={intl.formatMessage({ id: 'purchase_checkout_form.address2' })}
          id="address2"
          placeholder="Enter your address"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.address2}
          errorMessage={showErrorMessage('address2')}
          fullwidth
        />

        <Input
          label={intl.formatMessage({ id: 'purchase_checkout_form.postal_code' })}
          id="zipCode"
          placeholder="Enter your postal code"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.zipCode}
          errorMessage={showErrorMessage('zipCode')}
          fullwidth
          required
        />
      </div>
    </form>
  );

  const renderEditModal = () => (
    <>
      <div className={styles.modal_left_side}>
        <div className={styles.modal_left_side_default_address}>
          {defaultAddress && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              onClick={() => {
                setSelected(defaultAddress);
                setAction('');
              }}
            >
              <div className={styles.modal_left_side_wrapper}>
                <TitleButton title={defaultAddressText} small />
              </div>
              <div className={styles.address}>
                <InputRadio
                  className={styles.radio}
                  checked={selectedAddress && selectedAddress.id === defaultAddress.id}
                  value="address"
                  onChange={() => {}}
                />
                <div className={styles.modal_block_wrapper}>
                  <BillingAddressBlock
                    wrapperClassName={styles.addressInList}
                    selected={selectedAddress && selectedAddress.id === defaultAddress.id}
                    {...defaultAddress}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {otherAddresses && otherAddresses.length > 0 && (
            <div>
              <div className={styles.modal_left_side_wrapper}>
                <TitleButton title={otherAddressText} small />
              </div>

              {otherAddresses.map((address) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={styles.address}
                  onClick={() => {
                    setSelected(address);
                    setAction('');
                  }}
                >
                  <InputRadio
                    className={styles.radio}
                    checked={selectedAddress && selectedAddress.id === address.id}
                    value="address"
                    onChange={() => {}}
                  />
                  <div className={styles.modal_block_wrapper}>
                    <BillingAddressBlock
                      wrapperClassName={styles.addressInList}
                      selected={selectedAddress && selectedAddress.id === address.id}
                      {...address}
                    />

                    <div className={styles.row}>
                      <button
                        type="button"
                        className={clsx(
                          styles.addressInListButton,
                          selectedAddress && selectedAddress.id === address.id
                            ? styles.addressInListButtonSelected
                            : null
                        )}
                        onClick={() => setAddressAsDefault(address)}
                      >
                        <span>{setDefaultText}</span>
                      </button>

                      {!address.exigo && (
                        <button
                          type="button"
                          className={clsx(
                            styles.addressInListButton,
                            styles.addressInListButton_remove,
                            selectedAddress && selectedAddress.id === address.id
                              ? styles.addressInListButtonSelected
                              : null
                          )}
                          onClick={() => onDeleteAddress(address.id)}
                        >
                          <span>{removeText}</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.modal_right_side}>
        <div className={styles.modal_right_side_content}>
          {modalError && <div className={styles.modalError}>{modalError}</div>}
          {renderForm()}
        </div>
        <div className={styles.modal_right_side_footer}>
          <div className={styles.buttonContainer}>
            <Button size="large" type="button" onClick={onUpdateAddressClick} disabled={isDisabled}>
              {updateAddress}
            </Button>
            <Button
              variant="outlined"
              size="large"
              type="button"
              onClick={() => {
                fk.handleReset();
                showModal();
              }}
            >
              {cancel}
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  const renderNewModal = () => (
    <div className={styles.addAddressContainer}>
      <div className={styles.modal_right_side}>
        {modalError && <div className={styles.modalError}>{modalError}</div>}
        <div className={styles.modal_right_side_content}>{renderForm()}</div>
        <div className={styles.modal_right_side_footer}>
          <Button size="large" defaultWidth disabled={isDisabled} onClick={onAddAddressClick}>
            {saveText}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      isShown={modal === 'newAddress' || modal === 'editAddress'}
      modalType={modal}
      title={billingAddressText}
      idOverlay="address-modal"
      onCloseClick={() => {
        showModal();
        setAction('');
        fk.handleReset();
      }}
    >
      {modal === 'newAddress' && renderNewModal()}
      {modal === 'editAddress' && renderEditModal()}
    </Modal>
  );
};

const values = {
  address: PropTypes.string,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  exigo: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  lastName: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
};

BillingAddressModalForm.propTypes = {
  modal: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  modalError: PropTypes.string.isRequired,
  fk: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.shape(values),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleReset: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    isValid: PropTypes.bool,
    isValidating: PropTypes.bool,
    resetForm: PropTypes.func,
    setErrors: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
    submitForm: PropTypes.func,
    submitCount: PropTypes.number,
    setFieldValue: PropTypes.func,
    setStatus: PropTypes.func,
    setSubmitting: PropTypes.func,
    setTouched: PropTypes.func,
    setValues: PropTypes.func,
    touched: PropTypes.shape(values),
    values: PropTypes.shape(values),
    validateForm: PropTypes.func,
    validateField: PropTypes.func,
  }),
  onUpdateAddressClick: PropTypes.func.isRequired,
  onAddAddressClick: PropTypes.func.isRequired,
  onDeleteAddress: PropTypes.func.isRequired,
  setAddressAsDefault: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  setAction: PropTypes.func.isRequired,
  defaultAddress: PropTypes.shape(values),
  otherAddresses: PropTypes.arrayOf(PropTypes.object),
  selectedAddress: PropTypes.shape(values),
};

BillingAddressModalForm.defaultProps = {
  fk: PropTypes.shape({
    dirty: null,
    errors: null,
    handleBlur: null,
    handleChange: null,
    handleReset: null,
    handleSubmit: null,
    isSubmitting: null,
    isValid: null,
    isValidating: null,
    resetForm: null,
    setErrors: null,
    setFieldError: null,
    setFieldTouched: null,
    submitForm: null,
    submitCount: null,
    setFieldValue: null,
    setStatus: null,
    setSubmitting: null,
    setTouched: null,
    setValues: null,
    status: null,
    touched: null,
    values: null,
    validateForm: null,
    validateField: null,
  }),
  defaultAddress: null,
  selectedAddress: null,
  otherAddresses: null,
};

export default BillingAddressModalForm;
