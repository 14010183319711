import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { pageLinks } from '../../helpers/navigation';
import { createDocLink } from '../../helpers/utils';
import styles from './checkout-links.module.scss';

const CheckoutLinks = ({ countryCode, onEndOfSubscriptionWithTheValue }) => {
  const intl = useIntl();

  const yourPrivacy = intl.formatMessage({
    id: 'purchase_checkout.your_privacy_is_super_important',
  });
  const termsOfUse = intl.formatMessage({ id: 'purchase_checkout.termsOfUse' });
  const and = intl.formatMessage({ id: 'purchase_checkout.and' });
  const privacyPolicy = intl.formatMessage({ id: 'purchase_checkout.privacyPolicy' });
  const asloYouCanRefund = intl.formatMessage({ id: 'purchase_checkout.asloYouCanRefund' });
  const refundPolicy = intl.formatMessage({ id: 'purchase_checkout.refundPolicy' });
  const cancellationPolicy = intl.formatMessage({ id: 'purchase_checkout.cancellationPolicy' });

  return (
    <>
      <div className={styles.divider} />
      <p className={styles.smallText}>
        {yourPrivacy}
        &nbsp;
        <a
          href={createDocLink(pageLinks.terms, countryCode)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {termsOfUse}
        </a>
        &nbsp;{and}&nbsp;
        <a
          href={createDocLink(pageLinks.privacy, countryCode)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {privacyPolicy}
        </a>
        .&nbsp;{asloYouCanRefund}
        &nbsp;
        <a
          href={createDocLink(pageLinks.refund, countryCode)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {refundPolicy}
        </a>
        &nbsp;{and}&nbsp;
        <a
          href={createDocLink(pageLinks.cancellation, countryCode)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cancellationPolicy}
        </a>
        . &nbsp;
        {onEndOfSubscriptionWithTheValue}
      </p>
    </>
  );
};

CheckoutLinks.propTypes = {
  countryCode: PropTypes.string.isRequired,
  onEndOfSubscriptionWithTheValue: PropTypes.string.isRequired,
};

export default CheckoutLinks;
