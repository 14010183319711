import clsx from 'clsx';

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import PropTypes from 'prop-types';

import { useIntl } from 'gatsby-plugin-intl';
import { getPaymentTitle } from '../../helpers/utils';

import styles from './billing-payment-block.module.scss';

const query = graphql`
  query {
    ewallet: file(relativePath: { eq: "payment/e-wallet-logo.png" }) {
      childImageSharp {
        fixed(width: 34, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    card: file(relativePath: { eq: "payment/card-visa-master-maestro.png" }) {
      childImageSharp {
        fixed(width: 120, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    paypalcard: file(relativePath: { eq: "payment/card-visa-master-maestro.png" }) {
      childImageSharp {
        fixed(width: 120, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    astroPay: file(relativePath: { eq: "payment/astropay.jpg" }) {
      childImageSharp {
        fixed(width: 40, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    visa: file(relativePath: { eq: "payment/visa-pic.png" }) {
      childImageSharp {
        fixed(width: 34, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mastercard: file(relativePath: { eq: "payment/mastercard-pic.png" }) {
      childImageSharp {
        fixed(width: 34, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amex: file(relativePath: { eq: "payment/amex-pic.png" }) {
      childImageSharp {
        fixed(width: 34, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    bitpay: file(relativePath: { eq: "payment/bitpay-logo.png" }) {
      childImageSharp {
        fixed(width: 34, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    paypal: file(relativePath: { eq: "payment/paypal-logo.png" }) {
      childImageSharp {
        fixed(width: 34, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    coinpayments: file(relativePath: { eq: "payment/bitcoin.png" }) {
      childImageSharp {
        fixed(width: 24, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ebanx: file(relativePath: { eq: "payment/ebanx-logo.png" }) {
      childImageSharp {
        fixed(width: 34, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    top: file(relativePath: { eq: "payment/top-logo.png" }) {
      childImageSharp {
        fixed(width: 34, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ach: file(relativePath: { eq: "payment/plaid.png" }) {
      childImageSharp {
        fixed(width: 24, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    banktransfer: file(relativePath: { eq: "payment/dlocalbank.png" }) {
      childImageSharp {
        fixed(width: 24, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cashdeposit: file(relativePath: { eq: "payment/dlocalcash.png" }) {
      childImageSharp {
        fixed(width: 34, height: 34) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const BillingPaymentBlock = ({
  method,
  title,
  subtitle,
  isDefault,
  selected,
  wrapperClassName,
  isDisabled,
}) => {
  const intl = useIntl();

  const data = useStaticQuery(query);

  const imageMethod = method.replace('-', '');

  const defaultText = intl.formatMessage({ id: 'button.default' });

  const paymentType = (data[imageMethod] && data[imageMethod].childImageSharp.fixed) || '';

  return (
    <div
      className={clsx(
        styles.button,
        wrapperClassName && wrapperClassName,
        selected && styles.selected,
        isDisabled && styles.disabled
      )}
    >
      <div className={styles.content}>
        <div className={styles.rightSide}>
          <div className={styles.imgWrapper}>{paymentType && <Img fixed={paymentType} />}</div>
          <div className={styles.info}>
            <p>{title || getPaymentTitle(method)}</p>
            {subtitle && <p className={styles.subtitle}>{subtitle || ''}</p>}
          </div>
        </div>

        <div className="color_primary">{isDefault ? defaultText : ''} </div>
      </div>
    </div>
  );
};

BillingPaymentBlock.propTypes = {
  wrapperClassName: PropTypes.string,
  method: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  isDefault: PropTypes.bool,
  selected: PropTypes.bool,
  title: PropTypes.string,
};

BillingPaymentBlock.defaultProps = {
  wrapperClassName: null,
  subtitle: null,
  selected: false,
  title: null,
  isDefault: false,
};

export default BillingPaymentBlock;
