import clsx from 'clsx';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import React, { memo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'gatsby-plugin-intl';

import Button from '../button';
import { BTC_PROMO_COUPON_ID, isClient } from '../../helpers/utils';
import {
  checkCoupon,
  selectCoupon,
  clearCouponData,
  selectPaymentMethodFromSaved,
} from '../../store/actions';
import getCouponById from '../../services/api/actions/getCouponById';
import { selectCart } from '../../store/selectors';
import { selectCouponId, selectPreselectedCoupon } from '../../store/selectors/global';
import { selectEntityCoupons } from '../../store/selectors/entities';
import setNotification from '../../helpers/notifications';
import styles from './coupon-input.module.scss';

const query = graphql`
  query {
    commerce: file(relativePath: { eq: "payment/commerce.png" }) {
      childImageSharp {
        fixed(width: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const ForActionTypes = {
  RENEW: 'Renewal',
  UPGRADE: 'Upgrade',
  INITIAL: 'Entry',
};

const stateSelector = createStructuredSelector({
  coupons: selectEntityCoupons,
  selectedCoupon: selectCouponId,
  preselectedCoupon: selectPreselectedCoupon,
  cartData: selectCart,
});

const CouponInput = ({ page }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useStaticQuery(query);
  const { coupons, selectedCoupon, preselectedCoupon, cartData } = useSelector(stateSelector);
  const titleTxt = intl.formatMessage({ id: 'coupon_input.titleTxt' });
  const buttonText = intl.formatMessage({ id: 'coupon_input.buttonText' });

  // on apply click on item from the list
  const onApplyClick = (coupon) => {
    dispatch(checkCoupon(coupon));
  };

  const [inputValue, setValue] = useState('');
  const [askedCode, setCode] = useState(null);

  const isDisabled = !inputValue.length || askedCode === inputValue;

  const isCheckout = page === 'checkout';

  const coinPaymentCoupon =
    coupons && isCheckout && coupons.find((item) => item.couponCode === BTC_PROMO_COUPON_ID);

  const coinPaymentCouponApplied = BTC_PROMO_COUPON_ID === selectedCoupon;

  const discountBanner = coinPaymentCoupon && !coinPaymentCouponApplied;

  const getCoupon = async () => {
    if (askedCode !== inputValue) {
      setCode(inputValue);
      const response = await dispatch(getCouponById.withQuery(`?code=${inputValue}`).action());

      if (response.type === getCouponById.type.success) {
        const coupon = response.payload.data.data;
        setValue('');
        dispatch(checkCoupon({ ...coupon, validated: true }));
      }
    }
  };

  const clearCoupons = () => {
    dispatch(clearCouponData());
    setValue('');
    setCode(null);
  };
  // on main apply button click
  const setSelected = () => {
    dispatch(selectCoupon(preselectedCoupon));
  };

  const onCoinPaymentCouponClick = () => {
    if (!cartData.productCount) {
      return setNotification('error', {
        message: "You don't have any plans in your shopping cart",
        title: 'Error',
      });
    }

    dispatch(
      selectPaymentMethodFromSaved({
        method: 'coinpayments',
        username: isClient && localStorage.getItem('UN'),
      })
    );

    return dispatch(selectCoupon(BTC_PROMO_COUPON_ID));
  };

  const appliedCoupon = coupons && coupons?.find((item) => item.isApplied);

  const couponList = coupons?.filter((coupon) => {
    if (appliedCoupon?.allowCrossUsage) {
      return coupon.allowCrossUsage && coupon.currency === appliedCoupon.currency;
    }
    if (appliedCoupon) {
      return coupon.isApplied;
    }
    if (discountBanner || (!isCheckout && !appliedCoupon)) {
      return coupon.couponCode !== BTC_PROMO_COUPON_ID;
    }
    return coupon;
  });

  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.container}>
        <p className={styles.title}>{titleTxt}</p>
        <div className={styles.coupon}>
          <input
            className={styles.couponMaskInput}
            placeholder="Enter a coupon"
            id="my-input-id"
            value={inputValue}
            onChange={(e) => setValue(e.target.value)}
          />

          <Button
            className={clsx(styles.button, styles.buttonUseCoupon)}
            onClick={getCoupon}
            disabled={isDisabled}
          >
            <span>Apply</span>
          </Button>
        </div>
      </div>

      {coinPaymentCoupon && !coinPaymentCouponApplied && (
        <div className={styles.container}>
          <div
            role="button"
            tabIndex={-2}
            onKeyDown={onCoinPaymentCouponClick}
            className={clsx(styles.couponWrapper, styles.promo)}
            onClick={onCoinPaymentCouponClick}
          >
            <div className={styles.content}>
              Congratulation! <strong>Click here</strong> to apply{' '}
              <span className={styles.price}> 10% </span>
              discount
            </div>
          </div>
        </div>
      )}

      {coupons && (
        <>
          <div className={styles.container}>
            <div className="available-coupons">
              {couponList &&
              couponList.map((coupon) => {
                const forActionText = ForActionTypes[coupon.forAction] || 'Any';
                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                  <div
                    key={coupon.couponCode}
                    className={clsx(
                      styles.couponWrapper,
                      coupon.isApplied ? styles.applied : '',
                      coupon.couponCode === preselectedCoupon ? styles.preselected : ''
                    )}
                    onClick={() => onApplyClick(coupon)}
                  >
                    <div className={styles.content}>
                      <Img className={styles.image} fixed={data.commerce.childImageSharp.fixed} />
                      <div className={styles.couponTitle}>
                        <span className={styles.topTitle}> {coupon.couponCode}</span>
                        <div className={styles.bottomTitle}>
                          <span> Valid for: {coupon.validFor} days</span>
                          <div className={styles.divider} />
                          <span>Active</span>
                          <div className={styles.divider} />
                          <span>{forActionText}</span>
                        </div>
                      </div>
                    </div>
                    <span className={styles.price}> {coupon.discountValue} </span>
                  </div>
                );
              })}
            </div>
          </div>
          {!!couponList.length && (
            <>
              <div className={styles.divider} />
              <Button className={styles.button} onClick={setSelected} disabled={!preselectedCoupon}>
                <span>{buttonText}</span>
              </Button>
            </>
          )}

          {!!selectedCoupon.length && (
            <>
              <div className={styles.divider} />
              <Button variant="outlined" className={styles.buttonCancel} onClick={clearCoupons}>
                <span>Cancel</span>
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

CouponInput.propTypes = {
  page: PropTypes.string.isRequired,
};

export default memo(CouponInput, () => false);
