import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { FramePayProvider } from '@rebilly/framepay-react';
import PropTypes from 'prop-types';

import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import { createDocLink, isClient } from '../../helpers/utils';
import { framePayLink } from '../../utils/links';
import PreauthorizeFormCard from '../preauthorisation-form-card';
import rebillyConfig from '../../constants/rebillyConfig';
import styles from './preauthorisation.module.scss';
import Button from '../button';
import Modal from '../modal';
import createPayment from '../../services/api/actions/createPayment';
import validateCardAndAdd from '../../services/api/actions/validateCardAndAdd';
import setNotification from '../../helpers/notifications';
import { updatePaymentMethods } from '../../store/actions';

const strings = {
  title: 'Billing Address',
  addAddress: 'Add Address',
  submit: 'Submit',
  superImportant:
    'Your Privacy is super important to us - we’ll only use your information as described in our',
  termsOfUse: 'terms of use',
  and: 'and',
  privacyPolicy: 'privacy policy.',
  illAddItLater: "I'll add it late",
  cardTitle: 'Auto Renewal Authorization',
  billAddressTitle: 'Billing Address',
  payPalTitle: 'Auto Renewal Authorization',
  card: 'Card',
  payPal: 'PayPal',
  next: 'Next',
  select: 'Select',
  beforeProceedText:
    'Before you proceed to make the payment, please fill in your card info for pre-authorization for your monthly renewal.',
  doNotWorry: 'Do not worry, we will not charge your card right now!',

  oneDollarText:
    '    Please enter the card or paypal information for your monthly auto renewal payment. You\n' +
    '          will not be charged right now until the due date of your upcoming subscription. On your\n' +
    '          bank statement you may notice we have charged $1 but this amount will not be deducted from\n' +
    '          your bank account balance.',
};

const steps = {
  CARD: 0,
  PAYPAL: 1,
  ADDRESSES: 2,
  ADD_ADDRESS: 3,
};

const createModalTitle = (step) => {
  switch (step) {
    case steps.CARD:
      return strings.cardTitle;
    case steps.PAYPAL:
      return strings.cardTitle;
    default:
      return strings.billAddressTitle;
  }
};

const PreAuthorisation = ({ hideModal, countryCode, onSkipClick }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(steps.CARD);

  const showCardTab = () => setStep(steps.CARD);
  const showPaypalTab = () => setStep(steps.PAYPAL);

  const isCardTabActive = step === steps.CARD;
  const isPayPalTabActive = step === steps.PAYPAL;

  const validateCard = async (data) => {
    try {
      const { payload } = await dispatch(
        validateCardAndAdd.action({
          isDefault: true,
          paymentToken: data.id,
          redirectUrl: isClient && window.location.href,
        })
      );

      if (payload) {
        const { result, status, type, approvalUrl } = payload.data;
        const success = `${result}_${status}_${type}` === 'approved_completed_authorize';
        const waitForApproval = status === 'waiting-approval';

        if (waitForApproval && isClient) {
          window.location.href = approvalUrl;
          return;
        }

        if (success) {
          dispatch(updatePaymentMethods());
          setNotification('info', {
            message: 'Your card has been successfully added!',
          });
          hideModal();
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('preauthorize-error', err);
    }
  };

  const onPaypalSelect = () => {
    dispatch(
      createPayment.action({
        paymentMethod: 'paypal',
        redirectUrl: isClient && window.location.href,
      })
    );
  };

  return (
    <Modal
      isShown
      className={styles.modal}
      modalType="preAuthorisation"
      title="Auto Renewal Authorization"
      idOverlay="address-modal_pre"
      onCloseClick={createNavigateTo(pageLinks.checkout)}
    >
      <div>
        <div className={styles.preAuth}>
          <div className={styles.header}>
            <div className={styles.title}>{createModalTitle(step)}</div>
            <span
              role="button"
              onKeyDown={hideModal}
              tabIndex={-2}
              className={styles.closeIcon}
              onClick={hideModal}
            >
              <i className="icon-close" />
            </span>
          </div>

          {(step === steps.CARD || step === steps.PAYPAL) && (
            <>
              <div className={styles.textBlock}>
                <div className={styles.headerTextBlockOne}>{strings.beforeProceedText}</div>

                <div className={styles.notificationContainer}>
                  <span>{strings.oneDollarText}</span>
                </div>
              </div>
              <div className={styles.tabContainer}>
                <div
                  role="button"
                  onKeyDown={showCardTab}
                  tabIndex={-1}
                  onClick={showCardTab}
                  className={clsx([styles.tab, isCardTabActive && styles.activeTab])}
                >
                  {strings.card}
                </div>
                <div
                  role="button"
                  onKeyDown={showPaypalTab}
                  tabIndex={-2}
                  onClick={showPaypalTab}
                  className={clsx([styles.tab, isPayPalTabActive && styles.activeTab])}
                >
                  {strings.payPal}
                </div>
              </div>
            </>
          )}
          <div className={styles.tabContent}>
            {step !== steps.PAYPAL && (
              <FramePayProvider publishableKey={framePayLink} style={rebillyConfig.style}>
                <PreauthorizeFormCard
                  onReceiveToken={validateCard}
                  step={step}
                  setStep={setStep}
                  steps={steps}
                />
              </FramePayProvider>
            )}
          </div>
          {step === steps.PAYPAL && (
            <div className={styles.btnContainer}>
              <Button
                fullWidth
                size="large"
                disabled={false}
                className={styles.submitButton}
                onClick={onPaypalSelect}
              >
                {strings.select}
              </Button>
            </div>
          )}
          <div className={styles.textFooter}>
            <span>{strings.superImportant}</span>
            <a
              href={createDocLink(pageLinks.terms, countryCode)}
              className={styles.termsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.termsOfUse}
            </a>
            <span>{strings.and}</span>
            <a
              href={createDocLink(pageLinks.privacy, countryCode)}
              className={styles.termsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.privacyPolicy}
            </a>
          </div>
        </div>

        <div className={styles.btnContainer}>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            type="button"
            onClick={() => {
              onSkipClick();
              hideModal();
            }}
          >
            {strings.illAddItLater}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PreAuthorisation;

PreAuthorisation.propTypes = {
  hideModal: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  onSkipClick: PropTypes.func.isRequired,
};
