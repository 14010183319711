import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { graphql, useStaticQuery } from 'gatsby';
import styles from './link.module.scss';

const query = graphql`
  query btcLink {
    prismic {
      allBtc_guides {
        edges {
          node {
            btc_pdf {
              ... on PRISMIC__FileLink {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Links = ({ data, cartAmount, type }) => {
  const { buy, guide } = data;
  const buyUrl = buy.url.replace('$AMOUNT$', cartAmount);
  const staticData = useStaticQuery(query);
  const guidUrl = staticData.prismic.allBtc_guides.edges[0].node.btc_pdf.url;

  const renderIframe = () => (
    <div className={styles.btnContainer}>
      {/* <iframe */}
      {/*  src="https://changelly.com/static/payment-button-widget/index.html?paymentButtonTheme=default&buttonTextId=002&widgetLink=https%3A%2F%2Fwidget.changelly.com%3Ffrom%3Dbtc%252Cusd%26to%3Dbtc%26amount%3D1%26address%3D%26fromDefault%3Dusd%26toDefault%3Dbtc%26theme%3Ddefault%26merchant_id%3D7og15wixrcg4zqp1%26payment_id%3D%26v%3D3&isPopUp=true" */}
      {/*  width="220" */}
      {/*  height="48" */}
      {/*  frameBorder="0" */}
      {/* /> */}
      {/* <div id="changellyModal"></div> */}

      <a
        href={buyUrl}
        className={clsx(styles.btcButton, styles.green)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Buy BTC instantly
      </a>

      <a href={guidUrl} className={styles.btcButton} target="_blank" rel="noopener noreferrer">
        How to BUY Guide
      </a>
    </div>
  );

  if (type === 'iframe') {
    // eslint-disable-next-line no-console
    console.log('render iframe');
    return renderIframe();
  }

  return (
    <div className={styles.wrapper}>
      <a href={buyUrl} target="_blank" rel="noopener noreferrer">
        {buy.label}
      </a>

      <div className={styles.divider} />

      <a href={guidUrl} target="_blank" rel="noopener noreferrer">
        {guide.label}
      </a>
    </div>
  );
};

Links.defaultProps = { type: 'simple' };

Links.propTypes = {
  data: PropTypes.shape({
    buy: PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
    guide: PropTypes.string,
  }).isRequired,
  cartAmount: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default Links;
